import { Component, OnInit, SimpleChanges, inject } from '@angular/core';
import { ArticleService } from '../services/article.service';
import { SharedService } from '../services/shared.service';
import { Subscription } from 'rxjs';
import { env } from '../config/config';
import { SearchContextService } from '../services/search-context-service';
import { Meta, Title } from '@angular/platform-browser';
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
    // CategoryarrayForHome = ['All', ' Continuing surgical education ', ' Young BJS ', ' Cutting edge blog', 'Surgical news', 'Scientific surgery'];
    // CategoryarrayForListing = [' Young BJS '];
    private readonly searchContextService = inject(SearchContextService);
    start = 0;
    length = 8;
    journalid: string = '';
    articles: any[] = [];
    subscriptions: Subscription[] = [];
    aboutPadding=0
    bannerHeading = '';
    bannerImage = ''
    pageloading=true;
    articlePageLoading=true;
    chapter_list:any[]=[];
    search_list:any[]=[];
    articleListarray = new Array(8).fill(8);
    pageTopRatedLoading=true;
    billboardPageLoading=true;
    aboutPageLoader=true;
    constructor(private sharedService: SharedService,
        private articleService: ArticleService,
        private meta:Meta,
        private title:Title
    ) {}
    
    ngOnInit(): void {
        this.resetSearchContext();
        this.journalid = env.journalName;
        const params: any = {
            searchValue:''
        };
        this.meta.updateTag({ property: 'og:url', content: `${env.siteUrl}` });
          this.meta.updateTag({ property: 'og:type', content: 'website' });
          this.meta.updateTag({ property: 'og:title', content: `International Textbook of Leprosy` });
          // this.meta.updateTag({ property: 'og:description', content });

          // Optionally update the title
          this.title.setTitle('International Textbook of Leprosy');
        this.articleService.getArticleListLepra(this.journalid,params).subscribe(result => {
            this.chapter_list = result.data;
            this.articlePageLoading=false;
        })
        this.journalid = this.sharedService.getJournalId();
        // const params: any = {
        //     start: this.start,
        //     length: this.length,
        //     category: ''
        // };
        if(this.journalid){
            // const articleSub = this.articleService.getArticleList(this.journalid, params).subscribe(result => {
            //     this.articles = result.data;
            //     this.articlePageLoading=false;
            // });
            // this.subscriptions.push(articleSub);
            const subscription= this.sharedService.getAllThemsettingsData().subscribe(response => {
                this.bannerHeading = response ? response.data.banner_heading : '';
                this.bannerImage = response ? env.imageUrl + 'themes/' + response.data.banner_fullwidth_image : "";
                
            })
            this.subscriptions.push(subscription);
            // this.themeSettingsData = result.data;
            setTimeout(() => {
                this.pageloading=false;
                this.pageTopRatedLoading=false;
                this.billboardPageLoading=false;
                this.aboutPageLoader=false;
            }, 1000);
        }
    }

    onSearchEvent(searchValue:string) {
        // if(searchValue.length > 2){
            // const params: any = {
            //     searchValue:searchValue
            // };
            // this.articleService.getArticleListLepra(this.journalid,params).subscribe(result => {
            //     this.search_list = result.data;
            //     this.articlePageLoading=false;
            // })
        // }
        
    }

    filterEmit(search_filter:string[]){
    }

    getBillboardBoxWidth(event: number) {
        setTimeout(() => {
            if (event) {
                this.aboutPadding = event == 0 ? 190 : event;
            }
        }, 0);
    }

    private resetSearchContext(): void {
        this.searchContextService.setSelectedSpecialities([]);
        this.searchContextService.setSelectedContentType([]);
        this.searchContextService.setSearchContent(undefined);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => {
            if (!x.closed) {
                x.unsubscribe();
            }
        });
        this.meta.updateTag(
            { property: 'og:site_name', content: env.siteUrl },
          );
          this.meta.updateTag(
            { property: 'og:url', content: env.siteUrl },
          );
          this.meta.updateTag(
            { property: 'og:title', content: "International Textbook of Leprosy" },
          );
      
      
          this.meta.updateTag(
            { property: 'twitter:title', content: "International Textbook of Lepros" },
          );
          this.meta.updateTag(
            { property: 'twitter:card', content: "summary" },
          );
          this.meta.updateTag(
            { property: 'twitter:url', content: env.siteUrl },
          );
    }
}
