<section #contentLoaded id="article-view-page">
    <div class="article-view-page">
        <div class="container-fluid">
            <!-- <div class="row ">
            <div class="col-9" > -->
            <div class="row  pe-4 ps-2  pt-3 view-section">
                <div class="col mb-5 me-2 content-area-sm">
                    <div class="row ps-xs-0 ps-sm-0 ps-md-5 ps-lg-5 ps-xl-5 ps-xxl-5  ms-0 d-flex flex-xs-column-reverse flex-sm-column-reverse
                             flex-md-column-reverse flex-lg-column-reverse  flex-xl-column-reverse flex-xxl-row">
                        <div class="col-lg-12 col-xl-6 col-xxl-5 ps-0">
                            <!-- <div *ngIf="!categorydocheadloadie categorydocheadNotLoaded">
                                <h1 *ngIf="articleView.category_dochead" class="about-article"
                                    [innerHtml]="articleView.category_dochead"></h1>
                            </div> -->
                            <div *ngIf="!categorydocheadloading; else categorydocheadNotLoaded">
                                <h1 class="about-article">{{this.articleView?.article_category?.part?.title}}</h1>
                            </div>
                            <ng-template #categorydocheadNotLoaded>
                                <div>
                                    <h1 class="about-article skeleton"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h1>
                                </div>
                            </ng-template>

                        </div>
                        
                        <div class="col-lg-12 col-xxl-7 d-flex justify-content-end wrapper-container">
                            <div class="article-view-wrapper d-flex  justify-content-center me-3 me-xs-0">
                                <span class="pe-4 pe-xs-0 d-flex jusitfy-content-center custom-color">
                                    <fa-icon [icon]="['fas','eye']" aria-hidden="true"
                                        class="mt-2 m-3 icon-class"></fa-icon>

                                </span>
                                <span class="me-2 article-view-normal "
                                    [ngStyle]="{'background-color': activeViewFont == 'normal' ? '#fff' : ''}"
                                    (click)="openDyslexic('normal')">
                                    Normal View </span>

                                <span class="article-view-dyslexic "
                                    [ngStyle]="{'background-color': activeViewFont == 'dyslexic' ? '#fff' : ''}"
                                    (click)="openDyslexic('dyslexic')">
                                    Dyslexic View
                                </span>
                            </div>
                            <div class="share-button d-flex justify-content-center custom-color">
                                <div class="dropdown">
                                    <span class="dropdown-toggle" type="button" id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <fa-icon [icon]="['fas', 'share-nodes']" class="share-icon"></fa-icon>
                                    </span>
                                    <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                                        <li *ngIf="false">
                                            <span class="dropdown-item p-2" href="#">
                                                <share-button button="facebook" text="Share" showText
                                                    [url]="sharedUrl"></share-button>
                                            </span>
                                        </li>
                                        <li *ngIf="false">
                                            <span class="dropdown-item p-2" href="#">
                                                <share-button button="x" text="X" showText
                                                    [url]="sharedUrl"></share-button>
                                            </span>
                                        </li>
                                        <li *ngIf="false">
                                            <span class="dropdown-item p-2" tabindex="0" title="Linkedin">
                                                <share-button button="linkedin" text="Linkedin" showText
                                                    [url]="sharedUrl"></share-button>
                                            </span>
                                        </li>
                                        <li>
                                            <span class="dropdown-item p-2" tabindex="0" title="Email">
                                                <share-button button="email" text="Email" showText
                                                    [url]="sharedUrl"></share-button>
                                            </span>
                                        </li>
                                        <li><span class="dropdown-item p-2">
                                                <share-button button="copy" text="Copy Link" showText
                                                    [url]="sharedUrl"></share-button>
                                            </span>
                                        </li>

                                    </ul>

                                </div>
                            </div>
                        </div>
                        <ng-template #pageNotLoaded>
                            <div class="col-lg-12 col-xxl-7 d-flex justify-content-end wrapper-container">
                                <div class="article-view-wrapper d-flex  justify-content-center me-3 me-xs-0">
                                    <div class="skeleton">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="share-button d-flex justify-content-center">
                                    <div class="skeleton"></div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <div class="row ps-xs-0 ps-sm-0 ps-md-5 ps-lg-5 ps-xl-5 ps-xxl-5">
                        <!-- <div class="col" *ngIf="!articleTitleLoading;else articleTitleNotLoaded">
                            <h1 class="article-title" [innerHtml]="articleView.article_title"></h1>
                        </div> -->
                        <div class="col" id="chapter-heading" *ngIf="!articleTitleLoading; else articleTitleNotLoaded">
                            <h1 class="chapter-heading" [innerHtml]="articleView?.article_title">
                            </h1>
                        </div>
                        <ng-template #articleTitleNotLoaded>
                            <div class="col">
                                <h1 class="article-title skeleton skeleton-text skeleton-heading mt-4"></h1>
                            </div>
                        </ng-template>
                    </div>
                    <div class=" ps-xs-0 ps-sm-0 ps-md-5 ps-lg-8 ps-xl-8 ps-xxl-8 d-flex reverse-sm-col mt-1" *ngIf="!authorsLoaded ; else NoAuthorsLoaded">
                        <div class="col" id="author">
                            <h4 class="chapter-author"[innerHTML]="getAuthorlist(articleView?.authors)"></h4>
                        </div>

                    </div>
                    <ng-template #NoAuthorsLoaded>
                        <div class=" ps-xs-0 ps-sm-0 ps-md-5 ps-lg-8 ps-xl-8 ps-xxl-8 d-flex reverse-sm-col mt-1" >
                            <div class="col" id="author">
                                <h4 class="chapter-author skeleton skeleton-text "></h4>
                            </div>
    
                        </div>
                    </ng-template>
                    <div class=" ps-xs-0 ps-sm-0 ps-md-5 ps-lg-8 ps-xl-8 ps-xxl-8 d-flex reverse-sm-col mt-2" *ngIf="!citaionLoaded;else NotLoadedCitation">
                        <div class="col citation" id="citation">
                            <span class="ps-2">
                                <fa-icon class="custom-color" [icon]="['fas', 'quote-left']"></fa-icon> Citation
                            </span>
                            <!-- <span class="citation-text">
                                Cambau E, Williams DL. (2019) Chapter 5.2. Anti-leprosy Drugs: Modes of Action and
                                Mechanisms of Resistance in Mycobacterium leprae. In Scollard DM, & Gillis TP. (Eds.),
                                International Textbook of Leprosy. American Leprosy Missions, Greenville, SC.
                                <a href="https://doi.org/10.1489/itl.5.2"
                                    target="_blank">https://doi.org/10.1489/itl.5.2</a>
                            </span> -->
                            <span class="citation-text" [innerHtml]="returnCitation(articleView?.citation)">

                            </span>
                        </div>


                    </div>
                    <ng-template #NotLoadedCitation>
                        <div class=" ps-xs-0 ps-sm-0 ps-md-5 ps-lg-8 ps-xl-8 ps-xxl-8 d-flex reverse-sm-col mt-2" >
                            <div class="col citation" id="citation">
                                <span class="ps-2 skeleton skeleton-heading skeleton-text skeleton-text-80" >
                                    <!-- <fa-icon class="custom-color" [icon]="['fas', 'quote-left']"></fa-icon>  -->
                                </span>
                                <!-- <span class="citation-text">
                                    Cambau E, Williams DL. (2019) Chapter 5.2. Anti-leprosy Drugs: Modes of Action and
                                    Mechanisms of Resistance in Mycobacterium leprae. In Scollard DM, & Gillis TP. (Eds.),
                                    International Textbook of Leprosy. American Leprosy Missions, Greenville, SC.
                                    <a href="https://doi.org/10.1489/itl.5.2"
                                        target="_blank">https://doi.org/10.1489/itl.5.2</a>
                                </span> -->
                                <span class="citation-text ms-0 skeleton skeleton-heading skeleton-text" >
    
                                </span>
                            </div>
    
    
                        </div>
                    </ng-template>

                    <div class="row">
                        <div *ngIf="!articleContetLoading;else articleContentNotLoaded" class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-9">
                            <!---------------article content section----------->

                            <div #articlecontent id="article-content" class="article-content" [innerHtml]="html_new_content_dupe"
                            (mouseover)="showTooltip($event)" (click)="refClick($event)" (keyup.enter)="refClick($event)"
                            (keyup.space)="refClick($event)"></div>

                        </div>

                        <ng-template #articleContentNotLoaded>
                            <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-9">
                                <!---------------article content section----------->
                                <div class="article-content">
                                    <div *ngFor="let each of contentLoadingArray ; let ind=index">
                                        <p class="skeleton skeleton-text"
                                            [ngClass]="ind==(contentLoadingArray.length-1)?'skeleton-text-80':''">
                                        </p>
                                    </div>
                                    <div *ngFor="let each of contentLoadingArray ; let ind=index">
                                        <p class="skeleton skeleton-text"
                                            [ngClass]="ind==(contentLoadingArray.length-1)?'skeleton-text-80':''">
                                        </p>
                                    </div>

                                </div>

                            </div>
                        </ng-template>

                        
                        <div *ngIf="!articleContetLoading" class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3"  (window:scroll)="onScroll($event)">
                            <div  id="chapter-tab" class="chapter-tab" [ngClass]="{ 'fixed-position': isFixed }"[ngStyle]="isFixed ? { 'width.px': containerWidth } : {}">
                                <div class="mb-3 mt-3">
                                    <button class="btn chapter-button mt-3">Article Info</button>
                                </div>
                                <!-- Tab navigation -->
                                <ul class="nav nav-tabs" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-bs-toggle="tab" href="#tab1"
                                            role="tab">Outline</a>
                                    </li>
                                    <li class="nav-item" *ngIf="chapter_info==true">
                                        <a class="nav-link" data-bs-toggle="tab" href="#tab2" role="tab">Chapter
                                            info</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#tab3" role="tab">References</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#tab4"
                                            role="tab">Authors | Citation</a>
                                    </li>
                                    <!-- <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#tab5" role="tab">Tables</a>
                                    </li> -->
                                    <li class="nav-item" *ngIf="imagePaths.length > 0">
                                        <a class="nav-link" data-bs-toggle="tab" href="#tab6" role="tab">Images</a>
                                    </li>
                                </ul>

                                <!-- Tab content -->
                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="tab1" role="tabpanel">
                                        <!-- Article Outline -->
                                        <div id="page-content" class="page-content pl-4 ml-1 page-container">
                                            <div class="row">
                                                <p class="chapter-title">Section outline</p>
                                                <div class="timeline block mb-4 ms-3">
                                                    <div class="tl-item" *ngFor="let outline of articleOutline ; let ind=index">
                                                        <div class="tl-dot b-warning secondary" [class.list-content]="activeline[ind]"></div>
                                                        <div #introduction (click)="scrollToSection(ind,outline.outlineId)"
                                                            tabindex="0" class="tl-content secondary badge-content"
                                                            [class.font-bold]="activeline[ind]"
                                                            id={{outline.outlineId}}>
                                                            {{outline.title}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="tab2" role="tabpanel">
                                        <p>Content for Tab 2</p>
                                    </div>
                                    <div class="tab-pane fade" id="tab3" role="tabpanel">

                                        <div id="references" class="ref-list" [innerHtml]="referencesHTML">
                                        </div>

                                    </div>
                                    <div class="tab-pane fade" id="tab4" role="tabpanel">
                                        <div class="view-content">
                                            <div class="contributor-type-list">
                                                <p class="chapter-title">Contributing Authors</p>
                                                <div class="chapter-content">
                                                    <ng-container *ngFor="let authors of articleView.authors">
                                                    <p [innerHtml]="authorAff(authors)"></p>
                                                    </ng-container>
                                                    
                                                    <div class="col citation-tab" id="citation">
                                                        <span class="ps-2">
                                                            <fa-icon class="custom-color" [icon]="['fas', 'quote-left']"></fa-icon> Citation
                                                        </span>
                                                        <span class="citation-text-tab"  [innerHtml]="articleView?.citation">
                                                            <!-- <a href="https://doi.org/10.1489/itl.5.2"
                                                                target="_blank">https://doi.org/10.1489/itl.5.2</a> -->
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="tab5" role="tabpanel">
                                        <div class="table-w-caption-tab">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Drug<br>Susceptibility<br><span class="gloss-tooltip abbr" data-toggle="tooltip" data-original-title="mouse footpad assay for testing drug susceptibility in leprosy">MFP</span> Assay<sup>1</sup></th>
                                                        <th>Amino Acid Mutation<sup>2</sup></th>
                                                        <th>Number of<br>Resistant<br>Isolates</th>
                                                        <th>Reference</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colspan="4"><strong>Dapsone/folP1</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="center">R</td>
                                                        <td>Thr53Ala</td>
                                                        <td class="center">14</td>
                                                        <td class="center">
                                                            <sup data-footnote-id="alhmr"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-72" id="footnote-marker-72-2" rel="footnote" data-toggle="tooltip" data-original-title="Kai M, Matsuoka M, Nakata N, Maeda S, Gidoh M, Maeda Y, Hashimoto K, Kobayashi K, Kashiwabara Y. 1999. Diaminodiphenylsulfone resistance of Mycobacterium leprae due to mutations in the dihydropteroate synthase gene. FEMS Microbiology Letters 177:231–235.">[72]</a></sup>
                                                            <sup data-footnote-id="r3v0s"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-73" id="footnote-marker-73-1" rel="footnote" data-toggle="tooltip" data-original-title="Williams DL, Pittman TL, Gillis TP, Matsuoka M, Kashiwabara Y. 2001. Simultaneous detection of Mycobacterium leprae and its susceptibility to dapsone using DNA heteroduplex analysis. J Clin Microbiol 39:2083–2088.">[73]</a></sup>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="center">R</td>
                                                        <td>Thr53Ala</td>
                                                        <td class="center">14</td>
                                                        <td class="center">
                                                            <sup data-footnote-id="alhmr"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-72" id="footnote-marker-72-2" rel="footnote" data-toggle="tooltip" data-original-title="Kai M, Matsuoka M, Nakata N, Maeda S, Gidoh M, Maeda Y, Hashimoto K, Kobayashi K, Kashiwabara Y. 1999. Diaminodiphenylsulfone resistance of Mycobacterium leprae due to mutations in the dihydropteroate synthase gene. FEMS Microbiology Letters 177:231–235.">[72]</a></sup>
                                                            <sup data-footnote-id="r3v0s"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-73" id="footnote-marker-73-1" rel="footnote" data-toggle="tooltip" data-original-title="Williams DL, Pittman TL, Gillis TP, Matsuoka M, Kashiwabara Y. 2001. Simultaneous detection of Mycobacterium leprae and its susceptibility to dapsone using DNA heteroduplex analysis. J Clin Microbiol 39:2083–2088.">[73]</a></sup>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="center">R</td>
                                                        <td>Thr53Ala</td>
                                                        <td class="center">14</td>
                                                        <td class="center">
                                                            <sup data-footnote-id="alhmr"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-72" id="footnote-marker-72-2" rel="footnote" data-toggle="tooltip" data-original-title="Kai M, Matsuoka M, Nakata N, Maeda S, Gidoh M, Maeda Y, Hashimoto K, Kobayashi K, Kashiwabara Y. 1999. Diaminodiphenylsulfone resistance of Mycobacterium leprae due to mutations in the dihydropteroate synthase gene. FEMS Microbiology Letters 177:231–235.">[72]</a></sup>
                                                            <sup data-footnote-id="r3v0s"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-73" id="footnote-marker-73-1" rel="footnote" data-toggle="tooltip" data-original-title="Williams DL, Pittman TL, Gillis TP, Matsuoka M, Kashiwabara Y. 2001. Simultaneous detection of Mycobacterium leprae and its susceptibility to dapsone using DNA heteroduplex analysis. J Clin Microbiol 39:2083–2088.">[73]</a></sup>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="center">R</td>
                                                        <td>Thr53Ala</td>
                                                        <td class="center">14</td>
                                                        <td class="center">
                                                            <sup data-footnote-id="alhmr"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-72" id="footnote-marker-72-2" rel="footnote" data-toggle="tooltip" data-original-title="Kai M, Matsuoka M, Nakata N, Maeda S, Gidoh M, Maeda Y, Hashimoto K, Kobayashi K, Kashiwabara Y. 1999. Diaminodiphenylsulfone resistance of Mycobacterium leprae due to mutations in the dihydropteroate synthase gene. FEMS Microbiology Letters 177:231–235.">[72]</a></sup>
                                                            <sup data-footnote-id="r3v0s"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-73" id="footnote-marker-73-1" rel="footnote" data-toggle="tooltip" data-original-title="Williams DL, Pittman TL, Gillis TP, Matsuoka M, Kashiwabara Y. 2001. Simultaneous detection of Mycobacterium leprae and its susceptibility to dapsone using DNA heteroduplex analysis. J Clin Microbiol 39:2083–2088.">[73]</a></sup>
                                                        </td>
                                                    </tr>
                                                 
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div  class="tab-pane fade" id="tab6" role="tabpanel">
                                        <ng-container *ngIf="imagePaths.length > 0">
                                        <div  *ngFor="let image of imagePaths" class="figure-container">
                                            <div class="figure-content" (click)="refClick($event)">
                                                <img alt={{image.srcValue}}
                                                    src={{image.imageUrl}}
                                                    class="img-modal" figid="36890">
                                              
                                            </div>
                                        </div>
                                        </ng-container>
                                        <ng-container *ngIf="imagePaths.length == 0">
                                            <div>No image found</div>
                                        </ng-container>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<app-modal-fig *ngIf="isModalOpen" (close)="closeJoinModal()" [div_data]="div_data"></app-modal-fig>