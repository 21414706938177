<section class="contributors-content ">
    <div class="contributors-section">
        <h1>Contributors</h1>
        <div class="contributor-container">
            <div class="tabs">
                <button class="tab" [ngClass]="{'active': contributorData}"
                    (click)="contributorData = true; expertData = false;">
                    Contributing Authors
                </button>

                <button class="tab" [ngClass]="{'active': expertData}"
                    (click)="expertData = true; contributorData = false;">
                    Expert Reviewers
                </button>
            </div>
            <div class="contributors-grid" *ngIf="!ContributorLoading; else NotContributorLoaded">
                <div class="row g-4" *ngIf="contributorData">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12"
                        *ngFor="let contributor of contributors">
                        <div class="contributor m-1">
                            <h3 [innerHTML]="getAuthorlist(contributor._id)"> </h3>
                            <ul *ngFor="let articles of contributor['articles']">
                                <li>
                                    <a class="article_link"
                                        [routerLink]="['/', articles.heading, articles.part, articles.article_link]">
                                        {{ articles.article_title }}
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>

                </div>
                <div class="row g-4" *ngIf="expertData">
                    <div class=" col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12" *ngFor="let expert of expertReview">

                        <div class="contributor">
                            <h3 [innerHTML]="getAuthorlist(expert)"> </h3>

                        </div>

                    </div>
                </div>


            </div>
            <ng-template #NotContributorLoaded>
                <div class="contributors-grid ">
                    <div class="row">
                        <div class="col-xl-4 pb-4" *ngFor="let eachArray of loadingArray">
                            <div class="contributor">
                                <h3 class="skeleton skeleton-heading skeleton-text"> </h3>
                                <ul>
                                    <li>
                                        <p class="article_link skeleton skeleton-text skeleton-text-80">

                                        </p>
                                    </li>
                                    <li>
                                        <p class="article_link skeleton skeleton-text skeleton-Al-3">

                                        </p>
                                    </li>
                                </ul>
                            </div>


                        </div>


                    </div>

                </div>
            </ng-template>
        </div>
    </div>

</section>