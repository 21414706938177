import { Component, Input, OnInit } from '@angular/core';
import { env } from '../../config/config';
import { ArticleService } from '../../services/article.service';

@Component({
    selector: 'app-chapter-list',
    templateUrl: './chapter-list.component.html',
    styleUrl: './chapter-list.component.scss'
})
export class ChapterListComponent implements OnInit {
    journal_id = '';
    // chapter_list: any[] = [];
    @Input() chapter_list: any[] = [];
    tooltipVisible: { [key: number]: boolean } = {};
    ChapterLoading = true;
    loadingArray = new Array(24).fill(0)
    constructor(
        private articleService: ArticleService
    ) { }

    ngOnInit() {
        if (this.chapter_list) {
            this.ChapterLoading = false
        }

    }

    isObjectArray(authors: any[]): boolean {
        return typeof authors[0] === 'object';
    }

    showTooltip(index: number) {
        this.tooltipVisible[index] = true;
    }

    hideTooltip(index: number) {
        this.tooltipVisible[index] = false;
    }

    //for getting the author details
    getAuthorlist(authors: any[]) {//to show author data
        const fullname1: string[] = [];
        const fullname2: string[] = [];

        if (authors) {
            authors.map((element) => {
                let detail = '';
                detail =
                    "<span class='ref-pop-heading'><span class='tooltip-author'>" +
                    element.given_name +
                    ' ' +
                    element.surname;
                if (element.role) {
                    detail += ", "+element.role +
                    '</span></span>';
                }
                if (element.email) {
                    detail +=
                        "<div class='aff'>";
                    detail += "<span class='tooltip-email'>Author Email: </span>";
                    detail += '<p><a href="mailto:' + element.email + '">' + element.email + '</a></p>';
                    detail += '</div>';
                }
                if (element.affiliation) {
                    detail +=
                        "<div class='aff'>";
                    detail += "<span class='tooltip-aff'>Affiliation: </span>";
                    detail += "<p>" + element.affiliation + '</p>';
                    detail += '</div>';
                }
                let name = "<div class='tooltips affiliation-popover-content  p-0'>";

                name +=
                    "<span style='font-weight:600;'>" +
                    element.given_name +
                    ' ' +
                    element.surname +
                    "</span>" +
                    "<span class='tooltiptext'>" +
                    detail +
                    '</span></div></div>';
                fullname1.push(name);

                const sname = element.given_name + ' ' + element.surname;
                fullname2.push(sname);
            });
        }
        return "(" + fullname1.join(', ') + ")";
    }
}
