import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArticleService } from '../../services/article.service';
import { SharedService } from '../../services/shared.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-chapter-search-list',
    templateUrl: './chapter-search-list.component.html',
    styleUrl: './chapter-search-list.component.scss'
})
export class ChapterSearchListComponent implements OnInit {
    search_filter: string[] = [];
    search_value:string = '';
    journalid:string = '';
    articles:any[] = [];
    constructor(
        private route:ActivatedRoute,
        private articleService:ArticleService,
        private sharedService:SharedService,
        private sanitizer: DomSanitizer
    ){}

    ngOnInit() {
        this.route.queryParams.subscribe((queryPara:any) => {
            this.journalid = this.sharedService.getJournalId();
            this.search_value = queryPara['searchValue'] ?? '';
            this.search_filter = queryPara['filter']?.split(',') ?? [];
            const params: any = {
                searchValue: this.search_value,
                filters: this.search_filter.toString()
            };
            this.listArticle(params);
        });
    }

    formatAuthors(authors: any[]): string {
        return authors.map(author => `${author.given_name} ${author.surname}`).join(', ');
    }

    onSearchEvent(searchValue: string) {
        this.search_value = searchValue
    }

    emitSearch(search_filter: string[]) {
        this.search_filter = search_filter
    }

    showSearchList(click: boolean) {
        const params: any = {
            searchValue: this.search_value,
            filters: this.search_filter.toString()
        };
        this.listArticle(params);
    }

    highlightMatch(text: string, searchValue: string): SafeHtml {
        if (!text) {
            return this.sanitizer.bypassSecurityTrustHtml('');
        }
        if (!searchValue) {
            return this.sanitizer.bypassSecurityTrustHtml(text);
        }
        const regex = new RegExp(searchValue, 'gi');
        const highlightedText = text.replace(regex, (match) => `<span style="background-color: yellow;">${match}</span>`);
        return this.sanitizer.bypassSecurityTrustHtml(highlightedText);
    }

    listArticle(params:any){
        if(this.journalid){
            this.articleService.getSearchListLepra(this.journalid, params).subscribe(result => {
                this.articles = [];
                this.articles = result.data;
            });
        }
    }

    clearSearch(clear:boolean){
        this.search_value = '';
        this.search_filter = [];
        const params: any = {
            searchValue: '',
            filters: ''
        };
        this.listArticle(params);
    }
}
