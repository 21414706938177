<div class="index-container" *ngIf="!IndexLoading ;else NotIndexLoaded">

  <section class="index">
    <h2>Index</h2>
    <div style="text-align: center;" class="col 12 keywords-top-heading-section">
      <a class="keywords-top-heading-section-content" *ngFor="let letter of alphabets"
        (click)="focusToAlphabets(letter)" (keyup.enter)="focusToAlphabets(letter)"
        (keyup.space)="focusToAlphabets(letter)" tabindex="0">{{letter}}</a>
    </div>
    <div [innerHtml]="xmlData_dupe" #indexContent></div>
  </section>
</div>
<ng-template #NotIndexLoaded>
  <div class="index-container">
    <section class="index ">
      <h2 class="skeleton skeleton-small-heading skeleton-text h-100"></h2>
      <div class="">
        <p class="skeleton skeleton-text skeleton-text mb-1"></p>
        <p class="skeleton skeleton-text skeleton-text skeleton-text-80 mb-1"></p>
      </div>
      <div class="mt-2">
        <div class="skeleton skeleton-text skeleton-Al"></div>
      </div>
      <div class="mt-2">
        <div class="skeleton skeleton-text skeleton-Al-2"></div>
      </div>
      <div class="index-group">
        <ul>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>

        </ul>

      </div>
      <div class="index-group">
        <ul>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>

        </ul>

      </div>
      <div class="index-group">
        <ul>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-3 mb-1"></p>
          </li>
          <li>
            <p class="skeleton skeleton-text skeleton-Al-2 mb-1"></p>
          </li>

        </ul>

      </div>
    </section>
  </div>
</ng-template>