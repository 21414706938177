import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContributorsService } from '../services/contributors.service';
import { env } from '../config/config';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-contributors',
    templateUrl: './contributors.component.html',
    styleUrl: './contributors.component.scss'
})
export class ContributorsComponent implements OnInit, OnDestroy {
    contributors: any[] = [];
    ContributorLoading = true;
    loadingArray = new Array(24).fill(0)
    expertReview: any[] = [];
    contributorData = true;
    expertData = false;
    constructor(
        private route: ActivatedRoute,
        private contributeService: ContributorsService,
        private meta: Meta,
        private title: Title
    ) { }

    ngOnInit() {
        this.meta.updateTag({ property: 'og:url', content: `${env.siteUrl}/page/contributors` });
        this.meta.updateTag({ property: 'og:type', content: 'article' });
        this.meta.updateTag({ property: 'og:title', content: `Contributors` });
        // this.meta.updateTag({ property: 'og:description', content });

        this.title.setTitle(`Contributors |  International Textbook of Leprosy}`);
        // if (this.route.snapshot.params['page'] == 'contributors') {
        this.contributeService.getContributors().subscribe(result => {
            this.contributors = result.data;
            this.ContributorLoading = false
        })

        this.contributeService.getExpertReview().subscribe(result => {
            this.expertReview = result.data;
        })

        // }
    }

    //for getting the author details
    getAuthorlist(authors: any) {
        const fullname1: string[] = [];
        const fullname2: string[] = [];

        if (authors) {
            // authors.map((element) => {
            let detail = '';
            detail =
                "<span class='ref-pop-heading'><span class='tooltip-author'>" +
                authors.given_name +
                ' ' +
                authors.surname;
            if (authors.role) {
                detail += ", " + authors.role +
                    '</span></span>';
            }
            if (authors.email) {
                detail +=
                    "<div class='aff'>";
                detail += "<span class='tooltip-author'>Author Email: </span>";
                detail += '<p><a href="mailto:' + authors.email + '">' + authors.email + '</a></p>';
                detail += '</div>';
            }
            if (authors.affiliation) {
                detail +=
                    "<div class='aff'>";
                detail += "<span class='tooltip-author'>Affiliation: </span>";
                detail += "<p>" + authors.affiliation + '</p>';
                detail += '</div>';
            }
            let name = "<div class='tooltips affiliation-popover-content  p-0'>";

            name +=
                "<h3>" +
                authors.surname +
                ", <span class='highlight'>" +
                authors.given_name +
                "</span></h3>" +
                "<span class='tooltiptext'>" +
                detail +
                '</span></div></div>';
            fullname1.push(name);

            const sname = authors.given_name + ' ' + authors.surname;
            fullname2.push(sname);
            // });
        }
        return fullname1.toString();
    }
    ngOnDestroy(): void {
        this.meta.updateTag(
            { property: 'og:site_name', content: env.siteUrl },
        );
        this.meta.updateTag(
            { property: 'og:url', content: env.siteUrl },
        );
        this.meta.updateTag(
            { property: 'og:title', content: "International Textbook of Leprosy" },
        );


        this.meta.updateTag(
            { property: 'twitter:title', content: "International Textbook of Lepros" },
        );
        this.meta.updateTag(
            { property: 'twitter:card', content: "summary" },
        );
        this.meta.updateTag(
            { property: 'twitter:url', content: env.siteUrl },
        );
    }
}
